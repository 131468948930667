import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {Link} from 'gatsby'

export const query = graphql`
  query penis($id: String!) {
    sampleProject: sanitySampleProject(id: {eq: $id}) {
      id
      cover {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      year
      client
      clientlink
      _rawTask
      gallery {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          fluid(maxWidth: 1200) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
        alt
      }
    }
    projects: allSanitySampleProject(
      filter: {slug: {current: {ne: null}}}
      sort: {fields: [order], order: ASC}
    ) {
      edges {
        node {
          id
          order
          cover {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          slug {
            current
          }
          year
          client
          clientlink
        }
      }
    }
  }
`

// const {allProjects} = this.props.pathContext

const ProjectTemplate = (props) => {
  const {data, errors} = props
  const project = data && data.sampleProject
  const { prev, next, prevTitle, nextTitle } = props.pageContext
  const allProjects = data && data.projects
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO title={project.title || 'Untitled'} description={project.client} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <div className='project-wrapper'>
        <div className='other-projects mobile'>
          {allProjects.edges.map(project => (
            <li key={project.node.id}>
              <Link
              to={'/projects/' + project.node.slug.current}
              activeStyle={{
                fontWeight: 'bold',
              }}>
              {project.node.title}
              </Link>
            </li>
          ))}
        </div>
      {project && <Project next={next} prev={prev} {...project} />}
      </div>
    </Layout>
  )
}

export default ProjectTemplate
